import { btnDownload, canvasCrop, fileDetails } from "./elements";

export const downloadImage = () => {
  /**
   * download image
   */
  btnDownload.addEventListener("click", () => {
    const link = document.createElement("a");
    link.download = fileDetails.dataset.name?.split(".")[0] + "_anaglyph.jpg" || "anaglyph.jpg";
    link.href = canvasCrop
      .toDataURL("image/jpeg")
      .replace("image/jpeg", "image/octet-stream");
    link.click();
    link.id = "btn-download";

    setTimeout(() => {
      link.remove();
    }, 2000);
  });
};
