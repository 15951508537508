import {
  canvasCrop,
  canvasPreview,
  contextPreview,
  fileOutput,
} from "./elements";
import { resizeCanvas } from "./resizeCanvas";

/**
 * scale canvas to window size
 *
 * @param {number} sWidth
 * @param {number} sHeight
 */
export const scaleCanvasPreview = (sWidth: number, sHeight: number) => {
  let scale = 1;

  if (fileOutput.width > document.body.clientWidth) {
    scale = document.body.clientWidth / fileOutput.width;
  }

  contextPreview.scale(scale, scale);

  resizeCanvas(canvasPreview, fileOutput, sWidth * scale, sHeight * scale);

  contextPreview.drawImage(
    canvasCrop,
    0,
    0,
    sWidth,
    sHeight,
    0,
    0,
    fileOutput.width * scale,
    fileOutput.height * scale
  );
};
