import { fileDetails } from "./elements";

export const getImageDetails = (event: Event) => {
  // Get the selected file
  const target = event.target as HTMLInputElement;
  const file: File = (target.files as FileList)[0];

  if (file) {
    // Get the file name and size
    const { name: fileName, size } = file;
    // Convert size in bytes to kilo bytes
    const fileSize = (size / 1000).toFixed(2);
    // Set the text content
    const fileNameAndSize = `${fileName} (${fileSize}KB)`;
    fileDetails.textContent = fileNameAndSize;
    fileDetails.dataset.name = fileName;
  }
};
