import { fileInput } from "./elements";
import { getImage } from "./getImage";
import { getImageDetails } from "./getImageDetails";
import { resetNumericSliders } from "./resetNumericSliders";

export const fileInputChange = () => {
  fileInput.addEventListener("change", (event) => {
    getImage(event);
    getImageDetails(event);
    resetNumericSliders();
  });
};
