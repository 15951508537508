import {
  canvasCrop,
  canvasOutput,
  contextCrop,
  fileOutput,
  sliderX_Slider,
  sliderY_Slider,
} from "./elements";
import { resizeCanvas } from "./resizeCanvas";
import { scaleCanvasPreview } from "./scaleCanvasPreview";

/**
 * crop image after shift
 */
export const crop = () => {
  let sx = Number(sliderX_Slider.value);
  let sy = Number(sliderY_Slider.value);

  const sWidth = Number(fileOutput.width) - Math.abs(sx);
  const sHeight = Number(fileOutput.height) - Math.abs(sy);

  if (sx < 0) {
    sx = 0;
  }
  if (sy < 0) {
    sy = 0;
  }

  resizeCanvas(canvasCrop, fileOutput, sWidth, sHeight);

  contextCrop.globalCompositeOperation = "difference";

  contextCrop.drawImage(
    canvasOutput,
    sx,
    sy,
    sWidth,
    sHeight,
    0,
    0,
    sWidth,
    sHeight
  );

  scaleCanvasPreview(sWidth, sHeight);
};
