import { applyCanvasPattern } from "./applyCanvasPattern";
import { canvasTouch } from "./canvasTouch";
import { changeOrientation } from "./changeOrientation";
import { downloadImage } from "./downloadImage";
import { canvasPreview, fileOutput } from "./elements";
import { fileInputChange } from "./fileInputChange";
import { fileOutputLoad } from "./fileOutputLoad";
import { installButton } from "./installButton";
import { resetNumericSliders } from "./resetNumericSliders";
import { resizeCanvas } from "./resizeCanvas";
const s = require("./style.scss");

installButton();
resizeCanvas(
  canvasPreview,
  fileOutput,
  canvasPreview.width,
  canvasPreview.width
);
applyCanvasPattern();
fileOutputLoad();
resetNumericSliders();
downloadImage();
fileInputChange();
changeOrientation();
canvasTouch();
