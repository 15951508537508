import { installBtn, pointingFinger } from "./elements";

/**
 * install button
 */
interface BeforeInstallPromptEvent extends Event {
  prompt(): Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

export const installButton = () => {
  let installPrompt: BeforeInstallPromptEvent | null = null;

  window.addEventListener(
    "beforeinstallprompt",
    (event: BeforeInstallPromptEvent) => {
      event.preventDefault();
      installPrompt = event;
      installBtn.removeAttribute("hidden");
      pointingFinger.removeAttribute("hidden");
    }
  );

  installBtn.addEventListener("click", async () => {
    if (!installPrompt) {
      return;
    }
    const result = await installPrompt.prompt();
    console.log(`Install prompt was: ${result.outcome}`);
    installPrompt = null;
    installBtn.setAttribute("hidden", "");
    pointingFinger.setAttribute("hidden", "");
  });
};
