import { crop } from "./crop";
import {
  canvasGreenBlue,
  canvasOutput,
  canvasRed,
  contextOutput,
  sliderX_Slider,
  sliderY_Slider,
} from "./elements";

export const redrawOutput = () => {
  contextOutput.clearRect(0, 0, canvasOutput.width, canvasOutput.height);
  contextOutput.drawImage(canvasRed, 0, 0);
  contextOutput.drawImage(
    canvasGreenBlue,
    Number(sliderX_Slider.value),
    Number(sliderY_Slider.value)
  );
  crop();
};
