import { fileOutput } from "./elements";

export const getImage = (event: Event) => {
  const input = <HTMLInputElement>event.target;
  const reader = new FileReader();

  reader.onload = () => {
    const dataURL: string | ArrayBuffer | null = reader.result;
    fileOutput.src = <string>dataURL;
  };
  reader.onerror = (err) => console.log(err);

  if (input && input.files && input.files[0]) {
    reader.readAsDataURL(input.files[0]);
  }
};
