import {
  sliderX_Display,
  sliderX_Slider,
  sliderY_Display,
  sliderY_Slider,
} from "./elements";
import { initSlider } from "./initSlider";

export const resetNumericSliders = () => {
  initSlider(sliderX_Slider, sliderX_Display);
  initSlider(sliderY_Slider, sliderY_Display);
};
