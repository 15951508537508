import { canvasPreview, contextPreview } from "./elements";
/**
 * apply pattern on canvas
 */
export const applyCanvasPattern = () => {
  const imageObj = new Image();
  imageObj.onload = function () {
    const pattern = contextPreview.createPattern(imageObj, "repeat")!;

    contextPreview.rect(0, 0, canvasPreview.width, canvasPreview.height);
    contextPreview.fillStyle = pattern;
    contextPreview.fill();
  };
  imageObj.src = "./images/pattern.png";
};
