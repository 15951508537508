import { redrawOutput } from "./redrawOutput";

export const initSlider = (
  slider: HTMLInputElement,
  display: HTMLOutputElement
) => {
  slider.value = "0";
  display.textContent = slider.value;

  slider.addEventListener("input", (event) => {
    display.textContent = (event.target as HTMLInputElement).value;
    redrawOutput();
  });
};
