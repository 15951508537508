import {
  canvasPreview,
  sliderX_Display,
  sliderX_Slider,
  sliderY_Display,
  sliderY_Slider,
} from "./elements";
import { isTouchDevice } from "./isTouchDevice";
import { redrawOutput } from "./redrawOutput";

export const canvasTouch = () => {
  if (isTouchDevice()) {
    canvasPreview.addEventListener("touchstart", handleTouchStart, false);
    canvasPreview.addEventListener("touchmove", handleTouchMove, false);
  }
};

let xDown: number | null = null;
let yDown: number | null = null;

const handleTouchStart = (evt: TouchEvent) => {
  evt.preventDefault();
  xDown = evt.touches[0].clientX;
  yDown = evt.touches[0].clientY;
};

const handleTouchMove = (evt: TouchEvent) => {
  if (!xDown || !yDown) {
    return;
  }
  evt.preventDefault();

  const xUp = evt.touches[0].clientX;
  const yUp = evt.touches[0].clientY;

  const xDiff = Math.ceil(xDown - xUp);
  const yDiff = Math.ceil(yDown - yUp);

  const direction = getDirection(xDiff, yDiff);

  /* set slider values */
  sliderX_Slider.value = xDiff.toString();
  sliderX_Display.textContent = sliderX_Slider.value;
  sliderY_Slider.value = yDiff.toString();
  sliderY_Display.textContent = sliderY_Slider.value;

  /* redraw */
  redrawOutput();
};

const getDirection = (xDiff: number, yDiff: number) => {
  let direction = "";

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    if (xDiff > 0) {
      direction = "left";
    } else {
      direction = "right";
    }
  } else {
    if (yDiff > 0) {
      direction = "up";
    } else {
      direction = "down";
    }
  }
  return { direction, xDiff, yDiff };
};
