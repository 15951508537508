export const canvasRed: HTMLCanvasElement =
  document.querySelector("#canvasRed")!;
export const canvasGreenBlue: HTMLCanvasElement =
  document.querySelector("#canvasGreenBlue")!;
export const canvasOutput: HTMLCanvasElement =
  document.querySelector("#canvasOutput")!;
export const canvasCrop: HTMLCanvasElement =
  document.querySelector("#canvasCrop")!;
export const canvasPreview: HTMLCanvasElement =
  document.querySelector("#canvasPreview")!;
export const contextRed: CanvasRenderingContext2D = canvasRed.getContext("2d")!;
export const contextGreenBlue: CanvasRenderingContext2D =
  canvasGreenBlue.getContext("2d")!;
export const contextCrop: CanvasRenderingContext2D =
  canvasCrop.getContext("2d")!;
export const contextOutput: CanvasRenderingContext2D =
  canvasOutput.getContext("2d")!;
export const contextPreview: CanvasRenderingContext2D =
  canvasPreview.getContext("2d")!;
export const fileOutput: HTMLImageElement =
  document.querySelector("#fileOutput")!;
export const btnDownload: HTMLDivElement =
  document.querySelector(".btn-download")!;
export const sliders: HTMLDivElement = document.querySelector(".sliders")!;
export const installBtn: HTMLButtonElement =
  document.querySelector(".install-button")!;
export const pointingFinger: HTMLImageElement =
  document.querySelector(".pointing-finger")!;
export const sliderX_Slider: HTMLInputElement =
  document.querySelector("#x_coord_slider")!;
export const sliderX_Display: HTMLOutputElement =
  document.querySelector("#x_coord_display")!;
export const sliderY_Slider: HTMLInputElement =
  document.querySelector("#y_coord_slider")!;
export const sliderY_Display: HTMLOutputElement =
  document.querySelector("#y_coord_display")!;
export const fileInput: HTMLInputElement =
  document.querySelector("#fileInput")!;
export const fileDetails: HTMLParagraphElement =
  document.querySelector(".fileDetails")!;
