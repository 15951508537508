import { fileOutput, sliderX_Slider, sliderY_Slider } from "./elements";
import { scaleCanvasPreview } from "./scaleCanvasPreview";

export const changeOrientation = () => {
  screen.orientation.addEventListener("change", function (e) {
    const sx = Number(sliderX_Slider.value);
    const sy = Number(sliderY_Slider.value);
    const sWidth = Number(fileOutput.width) - Math.abs(sx);
    const sHeight = Number(fileOutput.height) - Math.abs(sy);

    setTimeout(() => {
      scaleCanvasPreview(sWidth, sHeight);
    }, 10);
  });
};
