import { getPictureData } from "./getPictureData";
import { resizeCanvas } from "./resizeCanvas";
import {
  btnDownload,
  canvasGreenBlue,
  canvasOutput,
  canvasPreview,
  canvasRed,
  contextGreenBlue,
  contextOutput,
  contextRed,
  fileOutput,
  sliders,
} from "./elements";
import { crop } from "./crop";

export const fileOutputLoad = () => {
  fileOutput.addEventListener("load", () => {
    /**
     * manipulate image's channels
     */
    resizeCanvas(canvasGreenBlue, fileOutput);
    resizeCanvas(canvasRed, fileOutput);

    let imgdGB = getPictureData(contextGreenBlue, fileOutput);
    let pixGB = imgdGB.data;

    let imgdR = getPictureData(contextRed, fileOutput);
    let pixR = imgdR.data;

    for (let i = 0, n = pixGB.length; i < n; i += 4) {
      pixR[i] = 0; // red channel
      pixGB[i + 1] = 0; // green channel
      pixGB[i + 2] = 0; // blue channel
    }
    contextGreenBlue.putImageData(imgdGB, 0, 0);
    contextRed.putImageData(imgdR, 0, 0);
    /**
     * place together green and blue channels with red channel on 'canvasOutput'
     */
    resizeCanvas(canvasOutput, fileOutput);
    resizeCanvas(canvasPreview, fileOutput);

    contextOutput.globalCompositeOperation = "difference";
    contextOutput.drawImage(canvasRed, 0, 0);
    contextOutput.drawImage(canvasGreenBlue, 0, 0);
    /**
     * init 'canvasCrop'
     */
    crop();
    /**
     * show download button and sliders
     */
    btnDownload.removeAttribute("hidden");
    sliders.removeAttribute("hidden");
  });
};
